.pc-app {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: start;
    background-color: transparent;

    .open-sans {
        font-family: "Open Sans", sans-serif;
    }
}
@import url("Assets/Styles/certificate-validator.scss"); /* Using a url */
@import url("Assets/Styles/pricing.scss"); /* Using a url */
@import url("Assets/Styles/websummit.scss"); /* Using a url */
@import url("Assets/Styles/bootstrap.min.scss"); /* Using a url */
