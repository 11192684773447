.pc-app {
    /* COMMON */
    .link-style-normal,
    .link-style-normal:hover {
        color: inherit;
    }

    /* PAGE STYLES */
    .cv-container-outer {
        display: flex;
        justify-content: center;
    }

    .cv-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1080px;
        margin-top: 32px;
        margin-bottom: 16px;
        padding: 0 12px;
    }

    /* left side */
    .cv-certificate-container {
        width: 30%;
    }

    .cv-certificate-shadow {
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.62);
        border-radius: 4px;
    }

    /* right side */
    .cv-details-container {
        width: 62%;
    }

    @media only screen and (max-width: 768px) {
        .cv-container {
            flex-direction: column;
            align-items: center;
        }

        .cv-certificate-container {
            width: 64%;
            max-width: 260px;
        }
        .cv-details-container {
            margin: 36px 0;
            width: 100%;
            padding: 0 12px;
            font-size: 90%;
        }
        .cv-details-header {
            font-size: 20px;
        }
        .info-badge {
            font-size: 80%;
        }
    }
    .cv-card {
        /* width: 653px; */
        height: 100%;
        left: 786px;
        top: 256px;

        background: #fcfcfc;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .cv-card-content {
        padding: 14px;
    }

    .cv-details-container table {
        margin-top: 32px;
        width: 100%;
        border-spacing: 0 15px;
    }

    .cv-details-container table td {
        margin-top: 12px;
        padding: 6px 0;
    }

    .cv-details-container table tbody tr td:first-child {
        font-weight: bold;
    }

    .cv-details-container p.about {
        color: #595959;
    }

    .cv-not-found-form {
        display: flex;
        align-items: center;
    }
    .cv-not-found-form button {
        margin-left: 24px;
        height: 100%;
        display: block;
        align-items: center;
        justify-content: center;
    }

    .cv-validation-text {
        min-width: 150px;
        max-width: 170px;
        text-align: start;
        color: #686868;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 768px) {
        .cv-not-found-form {
            flex-direction: column;
            align-items: flex-end;
        }
        .cv-not-found-form button {
            margin-top: 12px;
            max-width: 180px;
        }
    }

    @media only screen and (max-width: 480px) {
        .cv-validation-text {
            display: none;
        }
    }
}
