.pc-app {
    /* WAVES animation. Used for checkout */
    @keyframes waves-animation {
        0% {
            background-position: -200px;
        }
        60% {
            background-position: 400px;
        }
        100% {
            background-position: 400px;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .hide-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
